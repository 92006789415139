//
// Responsive unit
// --------------------------------------------------

@include unit-responsive($medias, 20px, 30px);

.unit-spacing-md {
  @include unit-spacing($medias, 10px, 25px);
}

// Unit Spacing
//

.unit-spacing-xxs {
  @include unit-spacing($medias, 10px, 15px);
}
.unit-spacing-xs {
  @include unit-spacing($medias, 10px, 20px);
}

.unit-spacing-md {
  @include unit-spacing($medias, 20px, 40px);
}

.unit-spacing-lg {
  @media (min-width: $screen-sm-min) {
    @include unit-spacing($medias, 25px, 45px);
  }
  @media (min-width: $screen-md-min) {
    @include unit-spacing($medias, 25px, 50px);
  }
}

.unit-spacing-xl {
  @media (min-width: $screen-sm-min) {
    @include unit-spacing($medias, 25px, 45px);
  }
  @media (min-width: $screen-md-min) {
    @include unit-spacing($medias, 25px, 70px);
  }
}