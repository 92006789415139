.counter {
  margin-bottom: 0;
  font-size: 48px;
  line-height: 1.2;
  letter-spacing: .04em;
  color: $brand-primary;
  @media (min-width: $screen-sm-min) {
    font-size: 58px;
  }
  @media (min-width: $screen-lg-min) {
    font-size: 88px;
  }
  &-cite {
    color: $gray-darker;
    font-size: $font-size-small;
    font-weight: 500;
    letter-spacing: .340em;
    //text-transform: uppercase;
  }
}

.countdown-row {
  @include display-flex;
  @include justify-content(space-between);
  @include align-items(center);
  @include flex-direction(column);
  @media (min-width: $screen-sm-min) {
    @include flex-direction(row);
  }
  @media (min-width: $screen-md-min) {
    margin-left: -40px;
  }
}

.countdown-section {
  display: inline-block;
  text-align: center;
  min-width: 25%;
  & + .countdown-section {
    position: relative;
    margin-top: 25px;
    @media (min-width: $screen-sm-min) {
      margin-top: 0;
    }
    &:before {
      @media (min-width: $screen-sm-min) {
        position: absolute;
        top: 40%;
        left: calc(0% - 13px);
        @include transform(translateY(-50%));
        content: ':';
        font-size: 60px;
        letter-spacing: .04em;
        color: $gray-lighter;
        @media (min-width: $screen-md-min) {
          font-size: 88px;
        }
      }

    }
  }
}

.countdown-amount {
  display: block;
  font-size: 60px;
  line-height: 1;
  letter-spacing: .04em;
  color: $brand-primary;
  @media (min-width: $screen-md-min) {
    font-size: 88px;
  }
}

.countdown-period {
  font-size: 12px;
  letter-spacing: .15em;
  color: $gray-darker;
  font-weight: 500;
  @media (min-width: $screen-md-min) {
    letter-spacing: .340em;
  }
}
