/*
* Static Layout
*/

.rd-navbar-static {
  display: block;

  // RD Navbar brand
  .rd-navbar-brand {
  }

  // RD Navbar Collapse
  .rd-navbar-collapse {
    &-items {
      &:last-child {
        display: none;
      }
    }
    @media (min-width: $screen-md-min) and (max-width: $screen-lg-min - 1) {
      margin-top: 10px;
      margin-bottom: 12px;
    }
    .list-inline {
      text-align: center;
      @media (min-width: $screen-lg-min) {
        text-align: left;
        margin-left: 10px;
      }
      @media (min-width: $screen-xl-min) {
        @include element-groups-custom-varinat-1(20px, 55px);
      }
      @media (min-width: 1800px) {
        @include element-groups-custom-varinat-1(20px, 80px);
      }
    }
    .unit {
      text-align: left;
    }
    .title {
      line-height: 14px;
    }
    h6 {
      margin-top: 5px;
    }
    .small {
      font-size: 10px;
      letter-spacing: .340em;
      font-weight: 400;
      //text-transform: uppercase;
    }
  }
  // RD Navbar nav wrap
  .rd-navbar-nav-wrap {
    text-align: right;
  }

  // RD Navbar Inner
  .rd-navbar-inner {
    position: relative;
    max-width: $rd-navbar-width;
    padding: $navbar-static-inner-padding;
    margin-left: auto;
    margin-right: auto;
    @include display-flex;
    @include align-items(center);
    @include flex-wrap(wrap);
    @media (min-width: $screen-lg-min) {
      padding-top: 70px;
      padding-bottom: 70px;
    }
    @media (min-width: $screen-xl-min + 20px) {
      padding-left: 0;
      padding-right: 0;
    }
    > * {
      flex-grow: 1;
    }
  }

  // RD Navbar Panel
  .rd-navbar-panel {
    @include display-flex;
    @include align-items(center);
    @media (min-width: $screen-md-min) and (max-width: $screen-lg-min - 1) {
      @include justify-content(center);
      width: 100%;
      margin: 15px 0;
    }
  }

  // RD Navbar Navigation
  .rd-navbar-nav {
    @include display-flex;
    @include align-items(center);
    @media (min-width: $screen-md-min) and (max-width: $screen-lg-min - 1) {
      @include justify-content(center);
      width: 100%;
      margin: 15px 0;
    }
    @include justify-content(flex-end);

    > li {
      @include display-flex-inline;
      @include align-items(center);
      //Postion Style
      > .rd-navbar-dropdown {
        display: block;
        position: absolute;
        left: 0;
        z-index: 5;
        width: $rd-navbar-dropdown-width;
        padding: $rd-navbar-dropdown-padding;
        margin-top: $rd-navbar-dropdown-offset;
        text-align: left;

        background: $rd-navbar-dropdown-background;

        .rd-navbar-dropdown {
          position: absolute;
          left: 100%;
          top: 0;
          margin-top: $rd-navbar-dropdown-offset;
          padding: $rd-navbar-dropdown-padding;
          z-index: 2;
        }
      }

      &:last-child {
        > .rd-navbar-dropdown {
          left: auto;
          right: 0;
          @media (min-width: 1800px) {
            left: 0;
            right: auto;
          }
        }
      }

      // RD Navbar Dropdown Base style
      .rd-navbar-dropdown {
        background: $rd-navbar-dropdown-background;
        > li {
          > a {
            display: block;
            padding: $rd-navbar-dropdown-item-padding;
            font-size: $rd-navbar-dropdown-item-font-size;
            font-weight: 400;
            color: $rd-navbar-dropdown-item-color;
            background: $rd-navbar-dropdown-item-background;
            letter-spacing: 0.03em;
            &:hover {
              color: $rd-navbar-dropdown-item-hover-color;
              background: $rd-navbar-dropdown-item-hover-background;
            }
          }
          &.focus,
          &.opened {
            > a {
              color: $rd-navbar-dropdown-item-active-color;
              background: $rd-navbar-dropdown-item-active-background;
            }
          }
        }

      }

      .rd-navbar-dropdown,
      .rd-navbar-megamenu {
        top: 100%;
        z-index: 15;
      }

      // RD Navbar Megamenu
      .rd-navbar-megamenu {
        display: table;
        table-layout: fixed;
        left: 10px;
        width: 98%;
        position: absolute;
        text-align: left;
        border-spacing: 31px 25px;
        table-layout: fixed;
        margin-top: 10px;
        max-width: $rd-navbar-width;
        background: $rd-navbar-megamenu-background;
        z-index: 4;

        > li {
          position: relative;
          display: table-cell;

          > p {
            font-weight: 400;
            //text-transform: uppercase;
            color: $white;
          }

          > ul {
            padding: 8px 9px;

            li + li {
              margin-top: 5px;
            }

            a {
              display: inline-block;
              color: $rd-navbar-megamenu-item-color;
              background: $rd-navbar-megamenu-item-background;

              &:hover {
                color: $rd-navbar-megamenu-item-hover-color;
                background: $rd-navbar-megamenu-item-hover-background;
              }
            }
          }

          & + li {
            padding-left: 10px;
            &:before {
              content: '';
              position: absolute;
              top: $rd-navbar-megamenu-gutter;
              bottom: $rd-navbar-megamenu-gutter;
              width: 1px;
              right: 100%;
              margin-right: 17px;
              background: lighten($rd-navbar-megamenu-background, 7.450980392156865%);
            }
          }
        }
      }

      & + li {
        margin-left: 24px;
        @media (min-width: $screen-xl-min) {
          margin-left: $navbar-static-nav-indent;
        }
      }

      > a {
        display: inline-block;
        padding: $rd-navbar-static-padding-link;
        color: $rd-navbar-nav-color;
        background: transparent;
        line-height: $rd-navbar-static-line-height-link;
        font-size: $rd-navbar-static-font-size-link;
        font-weight: $rd-navbar-static-font-weight-link;
        letter-spacing: $rd-navbar-static-letter-spacing-link;
        //text-transform: uppercase;
      }

      &.focus > a,
      &.opened > a,
      > a:hover,
      &.active > a {
        color: $brand-primary;
        background: transparent;
      }

      &.rd-navbar--has-dropdown {
        position: relative;
      }

      &.focus,
      &.opened {
        > .rd-navbar-dropdown, > .rd-navbar-megamenu {
          opacity: 1;
          visibility: visible;
          @include transform(translateY(0));
        }
      }

    }

    > .rd-navbar-submenu {
      > ul {
        box-shadow: $shadow-area-sm;
      }
      > .rd-navbar-submenu-toggle {
        position: relative;
        top: -1px;
        z-index: 2;
        margin-left: 5px;
        display: inline-block;
        width: $rd-navbar-static-submenu-togle-fz;
        height: $rd-navbar-static-submenu-togle-fz;
        line-height: $rd-navbar-static-submenu-togle-fz;
        font-size: $rd-navbar-static-submenu-togle-fz;
        color: $rd-navbar-color;
        &:before {
          content: '\f107';
          font-family: 'FontAwesome', sans-serif;
        }
      }

      .rd-navbar-submenu {
        // Submenu Arrow
        &.rd-navbar--has-dropdown {
          position: relative;
          .rd-navbar-submenu-toggle {
            color: $rd-navbar-static-submenu-togle-color;
            position: absolute;
            right: 15px;
            top: 50%;
            @include transform (translateY(-50%));
            display: inline-block;
            cursor: pointer;
            &:before {
              content: '\e315';
              font-family: 'Material Icons';
            }
          }
          .active,
          &:hover,
          &.opened {
            > .rd-navbar-submenu-toggle {
              color: $rd-navbar-static-submenu-togle-hover-color;
            }
          }
        }
      }

      > .rd-navbar-dropdown,
      > .rd-navbar-megamenu {
        opacity: 0;
        visibility: hidden;
        @include transform(translateY(30px));
      }
      &.focus,
      &.opened {
        > .rd-navbar-dropdown,
        > .rd-navbar-megamenu {
          opacity: 1;
          visibility: visible;
          @include transform(translateY(0));
        }
        > .rd-navbar-submenu-toggle {
          color: $rd-navbar-static-submenu-togle-hover-color;
        }
      }

      &.active {
        > .rd-navbar-submenu-toggle {
          color: $rd-navbar-static-submenu-togle-hover-color;
        }
      }

      .rd-navbar-submenu.focus > .rd-navbar-dropdown,
      .rd-navbar-submenu.opened > .rd-navbar-dropdown {
        display: block;
      }
    }
  }

  // RD Navbar Clone
  &.rd-navbar--is-clone {
    //IF Cloned
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    z-index: 999;
    display: block;
    background-color: $brand-primary;
    @include box-shadow($shadow-area-lg);
    @include transform(translateY(-60px));
    visibility: hidden;
    @include opacity(0);
    @include transition(.3s ease-out all);
    .rd-navbar-inner {
      padding-top: 15px;
      padding-bottom: 15px;
    }
    @media (min-width: $screen-md-min) and (max-width: $screen-lg-min - 1) {
      .rd-navbar-panel {
        display: none;
      }
    }

    &.rd-navbar--is-stuck {
      display: block;
      //If Cloned
      visibility: visible;
      @include opacity(1);
      @include transform(translateY(0));
    }
  }

  // RD Navbar Stuck
  &.rd-navbar--is-stuck,
  &.rd-navbar--is-clone {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    z-index: 999;
    background: $rd-navbar-background;
    @include box-shadow($shadow-area-lg);
  }
  &.rd-navbar--is-stuck {
    .rd-navbar-inner + .rd-navbar-inner {
    }
  }


}

.page-head-secondary {
  @media (min-width: $screen-md-min) and (max-width: $screen-lg-min - 1) {
    background-color: $gray-darker;
    min-height: 0;
  }
  @media (min-width: $screen-lg-min) {
    min-height: 0;
  }
  .rd-navbar-wrap {
    @media (min-width: $screen-lg-min) {
      height: auto !important;
    }
  }
}

//Classic Header
.rd-navbar-static.rd-navbar-classic {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  background-color: $gray-darker;

  @media (min-width: $screen-lg-min) {
    background-color: transparent;
  }
  .rd-navbar-inner {
    padding-top: 35px;
    padding-bottom: 35px;
    @media (min-width: $screen-xl-min) {
      padding-top: 70px;
      padding-bottom: 70px;
    }
  }
  .rd-navbar-nav {
    > li {
      > a {
        color: $white;
        position: relative;
        &:before {
          content: '';
          position: absolute;
          bottom: -3px;
          left: 0;
          width: 100%;
          max-width: 0;
          border-bottom: 2px solid transparent;
          @include transition(.15s ease-out all);
        }
        &:hover {
          &:before {
            border-color: $white;
            max-width: 100%;
          }
        }
      }
      &.active {
        > a {
          &:before {
            border-color: $white;
            max-width: 100%;
          }
        }
      }
    }
    .rd-navbar-submenu {
      .rd-navbar-submenu-toggle {
        color: $white;
      }
      &.focus {
        .rd-navbar-submenu-toggle {
          color: rgba($white,  .8);
        }
      }
    }

  }
  &.rd-navbar--is-stuck {
    background-color: $gray-darker;
    @media (min-width: $screen-md-min) and (max-width: $screen-lg-min - 1) {
      .rd-navbar-panel {
        visibility: hidden;
        @include opacity(0);
        margin: 0;
        max-height: 0;
      }
    }
    .rd-navbar-inner {
      padding-top: 11px;
      padding-bottom: 11px;
    }
  }
}

//RD Navbar Secondary
.rd-navbar-static.rd-navbar-secondary {
  .rd-navbar-inner {
    @media (min-width: $screen-lg-min) {
      padding-top: 50px;
      padding-bottom: 50px;
    }
    &:first-child {
      padding-top: 10px;
      padding-bottom: 10px;
      z-index: 1;
      .small {
        color: $gray;
        font-weight: 400;
      }
      &:before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: -100vw;
        right: -100vw;
        background-color: $table-bg-hover;
        z-index: -1;
      }
      .list-inline {
        text-align: right;
      }
    }
    &:nth-child(2) {
      @include justify-content(flex-start);
      @media (min-width: $screen-lg-min) {
        @include flex-wrap(nowrap);
      }
      @media (min-width: 1800px) {
        .rd-navbar-panel {
          min-width: 280px;
        }
      }
    }
    &:last-child {
      z-index: 1;
      padding: 0;
      &:before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: -100vw;
        right: -100vw;
        background-color: $gray-darker;
        z-index: -1;
      }
    }
  }
  .rd-navbar-nav {
    margin: 0;
    text-align: left;
    @include justify-content(space-between);
    > li {
      flex-grow: 1;
      text-align: center;
      @include justify-content(center);
      overflow: hidden;
      z-index: 10;
      a {
        position: relative;
        &:before {
          content: '';
          position: absolute;
          top: 0;
          bottom: 0;
          left: -100px;
          right: -100px;
          background-color: transparent;
        }
        display: block;
        padding: 22px 0;
        color: $white;
        background-color: transparent;
        &:hover {
          color: $white;
          background-color: transparent;
        }
      }
      &:hover, &.focus  {
        color: $white;
        background-color: $brand-primary;

      }
      &.active {
        color: $white;
        background-color: $brand-primary;
      }
      &.focus {
        z-index: 9;
        overflow: visible;
      }
      & + li {
        margin-left: 0;
      }

      &:last-child {
        color: $gray-darker;
        background-color: $brand-school-bus-yellow;
        > a {
          color: $gray-darker;
          background-color: transparent;
          &:hover {
            color: $white;
            background-color: transparent;
          }
        }
        &:hover {
          color: $white;
          background-color: $brand-primary;
        }
      }
    }
    > .rd-navbar-submenu {
      > .rd-navbar-submenu-toggle {
        color: $white;
      }
      &.active {
        > .rd-navbar-submenu-toggle {
          color: $white;
        }
      }
    }
  }
  &.rd-navbar--is-clone {
    .rd-navbar-inner {
      &:first-child, &:nth-child(2) {
        display: none;
      }
    }
  }
  &.rd-navbar--is-stuck {

  }
}