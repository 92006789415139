//
// Custom Thumbnails
// --------------------------------------------------

.thumbnail{
  padding: 0;
  margin: 0;
  border: none;
  border-radius: 0;

  .caption{
    padding: 0;
    color: $gray-base;
  }
}

.img-thumbnail,
.thumbnail{
  box-shadow: none;
}

// Make thumbnails to fit entire container width
.thumbnail-block {
  display: block;

  > img,
  a > img {
    width: 100%;
    height: auto;
  }
}


.thumbnail-default {
  img {
    @include transition(.3s ease-out all);
  }
  &:hover {
    img {
      @include transform(translateY(-10px));
    }
  }
}