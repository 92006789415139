/* 
 *  Owl Carousel - Animate Plugin
 */
.owl-carousel .animated {
  -webkit-animation-duration: 1000ms;
  animation-duration: 1000ms;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
.owl-carousel .owl-animated-in {
  z-index: 0;
}
.owl-carousel .owl-animated-out {
  z-index: 1;
}
.owl-carousel .fadeOut {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut;
}

@-webkit-keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}
@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

/* 
 * 	Owl Carousel - Auto Height Plugin
 */
.owl-height {
  -webkit-transition: height 500ms ease-in-out;
  -moz-transition: height 500ms ease-in-out;
  -ms-transition: height 500ms ease-in-out;
  -o-transition: height 500ms ease-in-out;
  transition: height 500ms ease-in-out;
}

/* 
 *  Core Owl Carousel CSS File
 */
.owl-carousel {
  display: none;
  width: 100%;
  -webkit-tap-highlight-color: transparent;
  /* position relative and z-index fix webkit rendering fonts issue */
  position: relative;
  z-index: 1;
}
.owl-carousel .owl-stage {
  position: relative;
  -ms-touch-action: pan-Y;
  @include display-flex;
}
.owl-carousel .owl-stage:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}
.owl-carousel .owl-stage-outer {
  position: relative;
  overflow: hidden;
  /* fix for flashing background */
  -webkit-transform: translate3d(0px, 0px, 0px);
}
.owl-carousel .owl-controls .owl-nav .owl-prev,
.owl-carousel .owl-controls .owl-nav .owl-next,
.owl-carousel .owl-controls .owl-dot {
  cursor: pointer;
  cursor: hand;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.owl-carousel.owl-loaded {
  display: block;
}
.owl-carousel.owl-loading {
  opacity: 0;
  display: block;
}
.owl-carousel.owl-hidden {
  opacity: 0;
}
.owl-carousel .owl-refresh .owl-item {
  display: none;
}
.owl-carousel .owl-item {
  position: relative;
  min-height: 1px;
  float: left;
  -webkit-backface-visibility: hidden;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 100%;
  -webkit-animation-duration: 1.6s;
  animation-duration: 1.6s;

}


.owl-carousel.owl-text-select-on .owl-item {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.owl-carousel .owl-grab {
  cursor: move;
  cursor: -webkit-grab;
  cursor: grab;
}
.owl-carousel.owl-rtl {
  direction: rtl;
}
.owl-carousel.owl-rtl .owl-item {
  float: right;
}

/* No Js */
.no-js .owl-carousel {
  display: block;
}

/* 
 * 	Owl Carousel - Lazy Load Plugin
 */
.owl-carousel .owl-item .owl-lazy {
  opacity: 0;
  -webkit-transition: opacity 400ms ease;
  -moz-transition: opacity 400ms ease;
  -ms-transition: opacity 400ms ease;
  -o-transition: opacity 400ms ease;
  transition: opacity 400ms ease;
}

/* 
 * 	Owl Carousel - Video Plugin
 */
.owl-carousel .owl-video-wrapper {
  position: relative;
  height: 100%;
  background: #000;
}
.owl-carousel .owl-video-play-icon {
  position: absolute;
  height: 80px;
  width: 80px;
  left: 50%;
  top: 50%;
  margin-left: -40px;
  margin-top: -40px;
  font: 400 40px/80px 'FontAwesome';
  cursor: pointer;
  z-index: 1;
  -webkit-backface-visibility: hidden;
  -webkit-transition: scale 100ms ease;
  -moz-transition: scale 100ms ease;
  -ms-transition: scale 100ms ease;
  -o-transition: scale 100ms ease;
  transition: scale 100ms ease;
  &:before{
    content: '\f144';
  }
}
.owl-carousel .owl-video-play-icon:hover {
  -webkit-transform: scale(1.3);
  transform: scale(1.3);
}
.owl-carousel .owl-video-playing .owl-video-tn,
.owl-carousel .owl-video-playing .owl-video-play-icon {
  display: none;
}
.owl-carousel .owl-video-tn {
  opacity: 0;
  height: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  -webkit-background-size: contain;
  -moz-background-size: contain;
  -o-background-size: contain;
  background-size: contain;
  -webkit-transition: opacity 400ms ease;
  -moz-transition: opacity 400ms ease;
  -ms-transition: opacity 400ms ease;
  -o-transition: opacity 400ms ease;
  transition: opacity 400ms ease;
}
.owl-carousel .owl-video-frame {
  position: relative;
  z-index: 1;
}

/*
 * Owl Navigation
 */

.owl-nav{

}

.owl-prev, .owl-next{
  display: none;
  position: absolute;
  bottom: 50px;
  font-size: 14px;
  text-align: center;
  cursor: pointer;
  color: rgba($white, 1);
  //text-transform: uppercase;
  font-weight: 500;
  letter-spacing: .120em;
  @include transition(.3s ease-out all);
  @media (min-width: $screen-sm-min) {
    display: block;
  }
}

.owl-prev{
  left: 30px;
  padding-left: 35px;
  &:before {
    position: absolute;
    left:  0;
    top: 44%;
    @include transform(translateY(-50%));
    content: '\e5c4';
    font-family: 'Material Icons', sans-serif;
    font-size: 20px;
  }
}

.owl-next{
  right: 30px;
  padding-right: 35px;
  &:after {
    position: absolute;
    right: 0;
    top: 44%;
    @include transform(translateY(-50%));
    content: '\e5c8';
    font-family: 'Material Icons', sans-serif;
    font-size: 20px;
  }
}

/*
 * Owl Pagination
 */

.owl-dots{
  text-align: center;
  margin-top: 40px;
  margin-left: -20px;
}

.owl-dot {
  display: inline-block;
  text-align: center;
  border-radius: 50%;
  position: relative;
  margin-left: 20px;
  width: 10px;
  height: 10px;
  outline: none;
  cursor: pointer;
  background-color: $gray-lighter;
  @include transition(.3s ease-out all);

  &:hover,
  &:focus,
  &.active {
    background-color: $brand-primary;
  }
}


.owl-carousel-wrap {
  position: relative;
  .product {
    margin-right: -4px;
  }
  .owl-dots {

  }
  &.wrap-fluid, &.owl-carousel-wrap-bullet {
    @media (min-width: $screen-lg-min) {
      .owl-dot {
        display: none;
      }
    }
  }
}

.owl-custom-navigation {
  visibility: hidden;
  @include opacity(0);
  @media (min-width: $screen-lg-min) {
    visibility: visible;
    @include opacity(1);
  }
  .owl-prev, .owl-next{
    z-index: 100;
    display: none;
    position: absolute;
    bottom: 25px;
    font-size: 14px;
    text-align: center;
    cursor: pointer;
    color: rgba($white, 1);
    //text-transform: uppercase;
    font-weight: 500;
    letter-spacing: .120em;
    @include transition(.3s ease-out all);
    @media (min-width: $screen-sm-min) {
      display: block;
    }
    &:hover {
      color: $brand-school-bus-yellow;
    }
  }

  .owl-prev{
    left: 30px;
    padding-left: 35px;
    &:before {
      position: absolute;
      left:  0;
      top: 44%;
      @include transform(translateY(-50%));
      content: '\e5c4';
      font-family: 'Material Icons', sans-serif;
      font-size: 20px;
    }
  }

  .owl-next{
    right: 30px;
    padding-right: 35px;
    &:after {
      position: absolute;
      right: 0;
      top: 44%;
      @include transform(translateY(-50%));
      content: '\e5c8';
      font-family: 'Material Icons', sans-serif;
      font-size: 20px;
    }
  }
  &-gray {
    @media (max-width: $screen-sm-min - 1) {
      position: relative;
      display: block;
      margin-top: 40px;
    }
    .owl-prev, .owl-next{
      top: auto;
      bottom: 0;
      display: block;
      color: $gray;
      @media (min-width: $screen-sm-min) {
        top: 30px;
        bottom: auto;
      }
      &:hover {
        color: rgba($gray, .3);
      }
    }
    .owl-prev {
      left: 0;
    }
    .owl-next {
      right: 0;
    }
  }
}