/*
* Navbar components
*/

// RD Navbar
.rd-navbar {
  display: none;
  background: $rd-navbar-background;
  box-shadow: $rd-navbar-shadow;
}

// RD Navbar Toggle
.rd-navbar-toggle {
  @include make-toggle(
          $rd-navbar-toggle-preset,
          $rd-navbar-min-line-height,
          $rd-navbar-min-font-size,
          $rd-navbar-fixed-toggle-color
  );
  background-color: transparent;
  border: none;
  &:focus{
    outline: none;
  }
  display: none;
}

// RD Navbar Collapse
.rd-navbar-collapse {
  &-toggle {
    @include make-toggle(
        $rd-navbar-collapse-toggle-preset,
        $rd-navbar-min-line-height,
        $rd-navbar-min-font-size,
        $white
    );
    background-color: transparent;
    display: none;
  }
}



// RD Navbar Brand
.rd-navbar-brand {
  .brand-name {
    color: $gray-dark;
    font-size: 25px;
    line-height: 40px;
    font-weight: 700;
  }

  .brand-slogan {
  }
}
// RD Navbar Nav
.rd-navbar-nav {
}

// RD Navbar Dropdown
.rd-navbar-dropdown {
  display: none;
}

.rd-navbar {
  // RD Navbar Search
  .rd-navbar-search {
    width: 350px;
    position: relative;
    .rd-search {
      position: absolute;
      top: 100%;
      bottom: 0;
      left: 0;
      right: 0;
      visibility: hidden;
      @include opacity(0);
      @include transform(translateY(20px));
      @include transition(.3s ease-out all);
    }
    &-toggle {
      span {
        position: relative;
        display: inline-block;
        font: 400 18px/36px "FontAwesome";
        cursor: pointer;

        &, &:before, &:after {
          text-align: center;
          width: 36px;
          height: 36px;
        }

        &:before, &:after {
          position: absolute;
          left: 0;
          top: 0;
        }

        &:before {
          content: '\f002';
          transition: .3s all ease;
          @include transform(scale(1) rotate(0deg));
        }

        &:after {
          content: '\f00d';
          transition: .3s all ease;
          @include transform(scale(0) rotate(-90deg));
        }
      }

      &.active {
        span {
          &:before {
            @include transform(scale(0) rotate(90deg));
          }

          &:after {
            @include transform(scale(1) rotate(0deg));
          }
        }
      }

      .rd-navbar-fixed &, .rd-navbar-fixed &:before {
        width: 48px;
        line-height: 48px;
      }
    }
    &-form-submit {
      @include make-toggle(
          $rd-navbar-search-toggle-preset,
          $rd-navbar-min-line-height,
          $rd-navbar-min-font-size,
          $rd-navbar-panel-color
      );
      position: absolute;
    }
    &.active {
      .rd-search {
        @include transform(translateY(0));
        visibility: visible;
        @include opacity(1);
      }
    }
  }
}

// RD Navbar Live Search Results
.rd-navbar-live-search-results {
  position: absolute;
  left: 4px;
  right: 4px;
  padding: 16px 8px 8px;
  top: 100%;
  font-size: 16px;
  line-height: 34px;
  color: #333;
  background: $white;
  box-shadow: $rd-navbar-shadow;
  opacity: 0;
  visibility: hidden;
  text-align: left;
  z-index: 998;

  .search-quick-result {
    padding-left: 8px;
    font-size: 14px;
    line-height: 30px;
    color: #757575;
  }

  .search_list {
    margin-top: 4px;
    font-size: 16px;
    line-height: 30px;

    li + li {
      margin-top: 2px;
    }

    .search_list li + li:last-child {
      margin-top: 8px;
      border-top: 1px solid #EBEBEB;
      padding-top: 7px;
    }
  }

  .search_link {
    display: block;
    padding: 8px;
    color: #757575;
    border-radius: 2px;

    &:hover {
      background: #F7F7F7;
    }

    p {
      margin-top: 0;
      font-size: 14px;
      display: none;
    }
  }

  .search_title {
    color: #212121;
    font-weight: 400;
  }

  .search_submit {
    display: block;
    text-align: center;
    padding: 8px;
    font-weight: 700;
    color: $brand-primary;
    //text-transform: uppercase;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;

    &:hover {
      background: #F7F7F7;
    }
  }

  &.active {
    visibility: visible;
    opacity: 1;
  }

  @media (min-width: $screen-lg-min) {
    .search_link {
      p {
        display: block;
      }
    }
  }
}

.rd-navbar-live-search-results {
  @include transform(translateY(-100%));

  &.active {
    @include transform(translateY(0));
  }
}

// Rd navbar shop

.rd-navbar-shop{
  display: inline-block;
  font-size: 22px;
  color: $white;
  position: absolute;
  right: 12px;
  @include transform(translateY(-50%));
  top: 50%;
  z-index: 9;
  transition: .3s;
  &:hover{
    color: $brand-primary;
  }
  &:focus{
    outline: 0;
    color: $brand-primary;
  }
}