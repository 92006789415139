// Custom button variants
//

@mixin button-variant-custom($clr, $bg, $bd, $clr-hover, $bg-hover, $bd-hover) {

  &,
  &:focus,
  &.focus,
  &:active,
  &.active,
  &:active:hover

  .open > &.dropdown-toggle {
    color: $clr;
    background-color: $bg;
    border-color: $bd;
  }


  &:hover,
  &:active:focus {
    color: $clr-hover;
    background-color: $bg-hover;
    border-color: $bd-hover;
  }

  &.disabled,
  &[disabled],
  fieldset[disabled] & {
    pointer-events: none;
    opacity: .5;
  }

  .badge {
    color: $bg;
    background-color: $clr;
  }
}

