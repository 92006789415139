/*
* Fixed Layout
*/
.rd-navbar-fixed {
  display: block;


  &.rd-navbar-secondary {
    .rd-navbar-inner {
      &:first-child {
        display: none;
      }
    }
  }


  // RD Navbar Brand
  .rd-navbar-brand {
    display: block;
    text-align: left;
    position: fixed;
    top: 6px;
    left: 56px;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 22px;
    line-height: 46px;
    height: 48px;
    z-index: 17;
    .brand-name {
      color: $white;
    }
    span.veil {
      display: block !important;
      color: $white;
      line-height: 48px;
    }
    img {
      display: none;
    }
  }
  .rd-navbar-nav {
    width: $rd-navbar-nav-min-width;
    height: 100%;
    left: 0;
    top: 0;
    overflow-y: auto;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
    font-size: 16px;
    line-height: 34px;
    color: $rd-navbar-color;
    background: $rd-navbar-fixed-panel-background;
    box-shadow: 0 0 11px 2px rgba(0, 0, 0, 0.17);
    z-index: 998;
    padding: 10px 0;

    &:before,
    &:after {
      content: '';
      display: block;
      height: $rd-navbar-min-height;
    }

    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-thumb {
      background: lighten($rd-navbar-background, 10%);
      border: none;
      border-radius: 0;
      opacity: .2;
    }

    &::-webkit-scrollbar-track {
      background: $rd-navbar-background;
      border: none;
      border-radius: 0;
    }

    li {
      position: relative;

      li > a {
        padding-left: 20px;
      }

      > a {
        position: relative;
        display: block;
        padding: 5px 45px 5px 15px;

        &:first-letter {
          //text-transform: uppercase;
        }
      }

      &.active > a {
        color: $gray-light;
        background: darken($rd-navbar-background, 5%);
      }

      &.focus > a, > a:hover {
        color: $rd-navbar-nav-hover-color;
        background: $rd-navbar-nav-active-background;
      }

      & + li, > img + a, > a + a, > a + ul {
        margin-top: math.div(($rd-navbar-min-height - $rd-navbar-min-line-height), 2);
      }
    }

    // RD Navbar Dropdown
    .rd-navbar-dropdown {
      > li > a {
        padding-left: 25px;
      }

      ul > li > a {
        padding-left: 30px;
      }
    }

  }

  // RD Navbar Panel
  .rd-navbar-panel {
    @include display-flex();
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    padding: math.div(($rd-navbar-fixed-height - $rd-navbar-fixed-line-height), 2);
    height: $rd-navbar-fixed-height;
    color: $rd-navbar-fixed-panel-color;
    z-index: 999;

    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
      box-shadow: $rd-navbar-fixed-shadow;
      background: $rd-navbar-fixed-panel-background;
    }
  }

  // RD Navbar Toggle
  .rd-navbar-toggle {
    display: inline-block;
    position: fixed;
    top: 4px;
    left: 3px;
    padding: 0;
  }

  .rd-navbar-collapse {
    position: fixed;
    text-align: center;
    right: 0;
    width: 320px;
    padding: 30px 15px 15px;
    opacity: 0;
    visibility: hidden;
    background-color: $white;



    .list-inline {
      text-align: left;

    }
    .list-inline-lg {
      margin-top: 15px;
      text-align: center;
    }
    .unit {
      text-align: left;
    }
    .title {
      line-height: 14px;
    }
    h6 {
      margin-top: 5px;
    }
    .small {
      font-size: 10px;
      letter-spacing: .340em;
      font-weight: 700;
      //text-transform: uppercase;
    }

    @include box-shadow(1px 2px 2px 0px rgba(0, 0, 0, .15));
    &-toggle {
      display: inline-block;
      position: fixed;
      top: 4px;
      right: 3px;
      padding: 0;
    }
    &.active {
      visibility: visible;
      @include opacity(1);
      -webkit-animation-name: tada;
      animation-name: tada;
    }
  }

  .rd-navbar-nav-wrap {
    @extend %rd-navbar-transition;
    position: fixed;
    top: -56px;
    left: 0;
    width: 232px;
    padding: 112px 0 56px;
    bottom: -56px;
    color: $white;
    background: $rd-navbar-background;
    z-index: 15;
    @include transform(translateX(-120%));

    &.active {
      @include transform(translateX(0));
    }
  }

  .rd-navbar-nav {
    display: block;
    height: 100%;
    overflow: auto;
    font-size: 16px;
    line-height: 26px;
    text-align: left;

    &:before, &:after {
      content: '';
      display: block;
      height: 8px;
    }



    li {
      > a {
        display: block;
        padding: 11px 56px 11px 16px;
        color: $rd-navbar-fixed-panel-color;
      }


      &.opened > a, a:hover {
        background: lighten($white, 75%);
      }

      &.active {
        .rd-navbar-submenu-toggle {
          &:after {
            color: $white;
          }
        }

        > a {
          color: $white;
          background: $brand-primary;
        }
      }
    }
  }

  .rd-navbar-dropdown,
  .rd-navbar-megamenu {
    display: none;
  }

  .rd-navbar-submenu {
    position: relative;
    @extend %rd-navbar-transition;

    li > a {
      padding-left: 32px;
    }

    .rd-navbar-dropdown li li > a,
    .rd-navbar-megamenu ul li li > a {
      padding-left: 48px;
    }

    &.opened {
      > .rd-navbar-dropdown,
      > .rd-navbar-megamenu {
        display: block;
      }
    }
  }

  // RD Navbar Search
  .rd-navbar-search {
    position: fixed;
    top: 0;
    left: 50px;
    right: 40px;
    height: 56px;
    z-index: 999;
    display: block;

    form {
      button[type="submit"] {
        display: none;
      }
    }

    &.active {
      .form-group {
        opacity: 1;
        visibility: visible;
        @include transform(translateY(0));
      }
    }

    &-form,
    &-toggle {
      right: 4px;
    }

    &-toggle {
      display: block;
      position: fixed;
      top: 4px;
      right: 56px;
      font-family: 'Material Icons';
      width: 48px;
      height: 48px;
      z-index: 18;
      color: $rd-navbar-color;
    }

    .form-group {
      position: fixed;
      top: 56px;
      padding-top: 0;
      left: 3px;
      right: 3px;
      height: 56px;
      background: $rd-navbar-background;
      z-index: 17;
      @extend %rd-navbar-transition;
      visibility: hidden;
      opacity: 0;
      @include transform(translateY(-20px));
      @include transition(.3s ease-out all);

      input {
        width: 100%;
        height: 46px;
        display: block;
        font-size: 22px;
        line-height: 46px;
      }
    }

    &-form, .rd-navbar-live-search-results {
      position: fixed;
      margin-right: 0;
      margin-left: auto;
    }

    .rd-navbar-live-search-results {
      top: $rd-navbar-fixed-height;
    }

    &-form {
      top: 0;
      z-index: 1000;
      left: 50px;
      right: 0;
      height: $rd-navbar-fixed-height;
      label {
        display: none;
      }

      &-input {
        padding: 8px 40px 8px 10px;
        visibility: hidden;
        opacity: 0;
        height: $rd-navbar-fixed-height - 16px;
        position: relative;
        top: 8px;
        background-color: $white;
        transition: .3s all ease;
        z-index: 1;
        width: 100%;
        border: 0;

        input {
          display: block;
          width: 100%;
          padding: 0 $rd-navbar-fixed-line-height 0 20px;
          height: $rd-navbar-fixed-line-height - 8;
          font-size: 16px;
          line-height: 34px;
          color: $rd-navbar-fixed-panel-color;
          background: darken($rd-navbar-fixed-panel-background, 5%);
        }
      }

      &-submit {
        position: absolute;
        top: math.div($rd-navbar-fixed-height - $rd-navbar-fixed-line-height, 2);
        right: 4px;
        opacity: 0;
        visibility: hidden;
        z-index: 9;
        background-color: transparent;
        border: none;
        &:focus {
          outline: none;
        }

      }
    }

    &-toggle {
      &.active {
        opacity: 0;
        visibility: hidden;
      }
    }

    &.active {
      .rd-navbar-search-form-input,
      .rd-navbar-search-form-submit {
        opacity: 1;
        visibility: visible;
      }
    }

    @media (min-width: $screen-md-min) {
      &-form, .rd-navbar-live-search-results {
        max-width: 340px;
      }

      .rd-navbar-live-search-results {
        right: 4px;
      }
    }

    @media (min-width: $screen-xs-min) {
      .rd-navbar-brand {
        .brand-name {
          font-size: $rd-navbar-fixed-font-size;
        }
      }
    }
  }

  // RD Navbar Nav
  .rd-navbar-nav {
    li {
      &:hover,
      &.focus {
        > a, > a:hover {
          color: $rd-navbar-nav-hover-color;
          background: $rd-navbar-nav-active-background;
        }
        > .rd-navbar-submenu-toggle {
          color: #fff;
          &:hover {
            cursor: pointer;
            color: #fff;
          }
        }
      }

      .rd-navbar-dropdown,
      .rd-navbar-megamenu {
        transition: opacity 0.3s, height 0.4s ease;
        opacity: 0;
        height: 0;
        overflow: hidden;
      }

      &.opened {
        > .rd-navbar-dropdown,
        > .rd-navbar-megamenu {
          opacity: 1;
          height: auto;
        }
        > a {
          background: $rd-navbar-nav-active-background;
          color: $rd-navbar-nav-hover-color;
        }
        > .rd-navbar-submenu-toggle {
          color: #fff;
          &::after {
            @include transform (rotate(180deg));
          }
        }
      }

    }

    .rd-navbar-submenu-toggle {
      &::after {
        content: '\f078';
        position: absolute;
        top: 22px;
        right: 0;
        margin-top: -22px;
        width: 65px;
        height: 48px;
        font: 400 14px "FontAwesome";
        line-height: 48px;
        text-align: center;
        transition: 0.4s all ease;
        z-index: 2;
        color: $rd-navbar-fixed-panel-color;
      }
    }
  }

  // RD Navbar States
  &.active {
    .rd-navbar-nav {
      @include transform(translateX(0));
    }
  }

  &.rd-navbar--on-search {
    @media (max-width: 1199px) {
      .brand-name {
        opacity: 0;
        visibility: hidden;
        @include transform(scale(0.7));
      }
    }
  }

  &.rd-navbar--is-clone {
    display: none;

    .rd-navbar-panel {
      @include transform(translateY(-110%));
    }

    &.rd-navbar--is-stuck {
      .rd-navbar-panel {
        @include transform(translateY(0));
      }
    }
  }

  .rd-navbar-fixed--visible {
    display: block;
  }

  .rd-navbar-fixed--hidden {
    display: none;
  }
}

html.rd-navbar-fixed-linked {
  .page-head {
    padding-top: 55px;
  }
}
.rd-navbar-fixed.rd-navbar-secondary {
  .rd-navbar-nav {
    > li {
      &:last-child {
        > a {
          color: $gray-darker;
          background-color: $brand-school-bus-yellow;
        }
      }
    }
  }
}
