.top-show {
  display: none;
}
.page-top {
  .top-none {
    display: none;
  }
  .top-show {
    display: inherit;
  }
}

.step_list {
  ol {
    counter-reset: step;
    li {
      counter-increment: step;

      .li_cont {
        border: #eee 2px solid;
        border-radius: 10px;
        overflow:  hidden;

        .step_ttl {
          padding: {
            top: .5em;
            right:  1em;
            bottom: .5em;
            left:  4em;
          }
          font-size: 1.6em;
          font-weight: bold;
          text-align: left;
          line-height: 1.2;
          color: #fff;
          position: relative;

          &::before {
            content: "step. " counter(step);
            font-size: .75em;
            font-weight: normal;
            line-height: 2.5em;
            position: absolute;
            top: .5em;
            left: 1em;
          }

          @media only screen and (max-width:768px) {
            font-size: 1em;
            padding: {
              top: 1em;
              bottom: 1em;
            }
            &::before {
              top: 1em;
            }
          }
        }
        .desc {
          padding: 2em 3em;
          p {
            font-size: #{math.div(20, 16)}em;
            text-align: left;
            text-indent: 0em;
            line-height: 2;

            + p {
              margin-top: 1em;
            }
          }
          @media only screen and (max-width:768px) {
            padding: 1em;
            p {
              font-size: #{math.div(14, 16)}em;
            }
          }
        }
      }
      &:nth-child(1) {
        .step_ttl {
          background-color: $brand-sunglow;
        }
      }
      &:nth-child(2) {
        .step_ttl {
          background-color: $brand-success;
        }
      }
      &:nth-child(3) {
        .step_ttl {
          background-color: $brand-regent-st-blue;
        }
      }
      &:nth-child(4) {
        .step_ttl {
          background-color: $brand-info;
        }
      }
      &:nth-child(5) {
        .step_ttl {
          background-color: $brand-primary;
        }
      }

      &:not( :first-child ) {
        &::before {
          margin: {
            top:  1em;
            bottom: 1em;
          }
          content: "\f0d7";
          font-weight: 400;
          font-family: $fa;
          font-size: 4em;
          color: $brand-primary;
          text-align: center;
          line-height: 1;
          display: block;

          @media only screen and (max-width:768px) {
            margin: {
              top: .2em;
              bottom: .2em;
            }
          }
        }
      }
    }
  }
}
