.rd-navbar-brand {
  .brand-name {
    color: $brand-primary;
    font-family: 'Muli', sans-serif;
    font-size: 32px;
    font-weight: 800;
    letter-spacing: 0.05em;
    text-shadow: 1px 2px 2px rgba(0,0,0,0.2);

    img {
      margin-right:#{math.div(8, 16)}em;
    }
  }
}
.rd-navbar-fixed {
  .rd-navbar-brand {
    .brand-name {
      color: $brand-primary;

      img {
        height: 41px;
        margin-right: 0.3em;
        display: inline-block;
      }
    }
  }
}
.rd-navbar-nav {
  .en,
  .ja {
    display: block;
    text-align: center;
  }
  .ja {
    font-size:#{math.div(10, 14)}em;
    margin-top:#{math.div(3, 10)}em;
  }
  > li {
    > .rd-navbar-dropdown {
       width: auto !important;
       min-width: 185px;

       > li {
         > a {
          font-size:#{math.div(12, 16)}em !important;
          word-break: keep-all;
        }
      }
    }
  }
}
h1,
h2,
h3,
h4,
h5,
h6 {
  .en,
  .ja {
    display: block;
  }
  .ja {
    margin-top:#{math.div(12, 18)}em;
  }
}
h1 {
  .ja {
    font-size:#{math.div(10, 40)}em;
    font-weight: 700;
  }
}
h2,
h3,
h4,
h5,
h6 {
  .ja {
    font-size:#{math.div(18, 40)}em;
  }
}

.table {
  &_hrzn {
    width: 100%;
    tr {
      th,td {
        padding: 2em .5em;
        border-top: 2px solid #eee;
        border-bottom: 2px solid #eee;
        font-weight: bold;
        text-align: left;
      }
      th {
        min-width: 20%;
      }
      td {
        font-weight: normal;
      }
    }
  }
  &_light {
    width: 100%;
    table-layout: fixed;
    margin: 0 auto;
    font-size:#{math.div(24, 16)}em;

    tr {
      th,td {
        padding: 1em .5em;
        font-size: 0.8em;
        font-weight: bold;
        text-align: center;
        border: 2px solid #fff;
        box-sizing: border-box;
      }
      th {
        $tablettl-width: 25;
        $caution-width: 25;

        min-width: 20%;
        background-color: $gray;
        color: #fff;

        &:first-child {
          width: #{$tablettl-width} + "%";
        }
        &:not(:first-child) {
          width: #{math.div((100 - $tablettl-width - $caution-width), 3)} + "%";
        }
        &.caution{
          width: #{$caution-width} + "%";
          background-color: $brand-primary;
        }
      }
      td {
        background-color: $gray-lighter;

        &.caution {
          color: $brand-primary;
          background-color: $brand-regent-st-blue;
        }
      }
      &:first-child {
        td {
          background-color: #fff;
        }
      }
    }
    @media only screen and (max-width:768px) {
      font-size:#{math.div(10, 16)}em;
      display: initial;
    }
  }
}

.list {
  &_sns {
    margin-top: #{math.div(30, 16)}em;
    display: flex;
    align-items: center;

    li {
      a {
        width: 3em;
        height: 3em;
        font-size: #{math.div(12, 16)}em;
        color: #fff;
        background-color: $brand-primary;
        border: $brand-primary 2px solid;
        border-radius: 50%;
        display: block;
        position: relative;

        &:hover {
          color: $brand-primary;
          background-color: transparent;
        }

        i {
          font-size: 1em;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
      & + li {
        margin-left: #{math.div(25, 16)}em;
      }
    }
  }
  &_step {
    margin: -1em 0;
    counter-reset: step;

    li {
      margin: 2em 0;
      padding-left: 4em;
      counter-increment: step;
      position: relative;

      &:before {
        content: "step" counter(step);
        padding: .2em .4em;
        line-height: 1;
        border: #ccc 1px solid;
        position: absolute;
        left: 0;
        top: 0;
      }

      dl {
        dt {
          font-weight: bold;

          &:not(:first-child) {
            margin-top: 1.5em;
          }
        }
        dd {
          margin-top: 1em;
          font-weight: normal;
        }
      }
    }
  }
}

ul.li_2col {
  width: 90%;
  margin: 40px auto 0;
  padding: 0 100px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  li {
    width: calc(33.33333% - 30px );
    text-align: left;
    position: relative;
    padding-left: 30px;
    margin-bottom: 10px;

    &::before {
      background: #888;
      content: "";
      height: 15px;
      width: 15px;
      left: 0;
      position: absolute;
      top: 8px;
    }
  }
  @media only screen and (max-width:768px) {
    display: initial;

    li {
      width: 100%;
    }
  }
}

.g-recaptcha {
  margin: 0 auto;
  display: table;
}

/* splite */
.icon-sprite-origin {
  width: 70px;
  height: 64px;
  background-image: url(../images/common/spritesheet.png);
  background-repeat: no-repeat;
  display: inline-block;
  & + h5 {
    margin-top: 20px;
  }
}

.breadcrumb-classic {
  &.mv {
    margin-top: 80px;

    &::after {
      display: none;
    }
  }
}

.section-lg-top-400 {
  padding-top: 400px;
}

.sprite-home-icon-origin {
  &-00 {
    background-position: (-70px * 0) (-72px * 0 - 8px);
  }
  &-01 {
    background-position: (-70px * 0) (-72px * 1 - 8px);
  }
  &-02 {
    background-position: (-70px * 0) (-72px * 2 - 8px);
  }
  &-03 {
    background-position: (-70px * 0) (-72px * 3 - 8px);
  }
  &-04 {
    background-position: (-70px * 0) (-72px * 4 - 8px);
  }
  &-05 {
    background-position: (-70px * 0) (-72px * 5 - 8px);
  }
  &-06 {
    background-position: (-70px * 0) (-72px * 6 - 8px);
  }
  &-10 {
    background-position: (-70px * 1) (-72px * 0 - 8px);
  }
  &-11 {
    background-position: (-70px * 1) (-72px * 1 - 8px);
  }
  &-12 {
    background-position: (-70px * 1) (-72px * 2 - 8px);
  }
  &-13 {
    background-position: (-70px * 1) (-72px * 3 - 8px);
  }
  &-14 {
    background-position: (-70px * 1) (-72px * 4 - 8px);
  }
  &-15 {
    background-position: (-70px * 1) (-72px * 5 - 8px);
  }
  &-16 {
    background-position: (-70px * 1) (-72px * 6 - 8px);
  }
  &-20 {
    background-position: (-70px * 2) (-72px * 0 - 8px);
  }
  &-21 {
    background-position: (-70px * 2) (-72px * 1 - 8px);
  }
  &-22 {
    background-position: (-70px * 2) (-72px * 2 - 8px);
  }
  &-23 {
    background-position: (-70px * 2) (-72px * 3 - 8px);
  }
  &-24 {
    background-position: (-70px * 2) (-72px * 4 - 8px);
  }
  &-25 {
    background-position: (-70px * 2) (-72px * 5 - 8px);
  }
  &-26 {
    background-position: (-70px * 2) (-72px * 6 - 8px);
  }
  &-30 {
    background-position: (-70px * 3) (-72px * 0 - 8px);
  }
  &-31 {
    background-position: (-70px * 3) (-72px * 1 - 8px);
  }
  &-32 {
    background-position: (-70px * 3) (-72px * 2 - 8px);
  }
  &-33 {
    background-position: (-70px * 3) (-72px * 3 - 8px);
  }
  &-34 {
    background-position: (-70px * 3) (-72px * 4 - 8px);
  }
  &-35 {
    background-position: (-70px * 3) (-72px * 5 - 8px);
  }
}
