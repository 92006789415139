// Circle Progress Bars
// --------------------------------------------------

.progress-bar-circle {
  display: inline-block;
  position: relative;
  text-align: center;
  line-height: 1.2;



  canvas {
    vertical-align: middle;
  }

  span {
    position: absolute;
    top: 50%;
    left: 50%;
    font-size: 50px;
    letter-spacing: .04em;
    color: $brand-primary;
    transform: translate(-50%, -50%);
  }
}