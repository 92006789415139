// Typography

// [converter] $parent hack
@mixin text-emphasis-variant($parent, $color, $hover-color: darken($color, 10%)) {
  #{$parent} {
    color: $color;
  }
  a#{$parent}:hover {
    color: $hover-color;
  }
}
