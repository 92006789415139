/*
* @subsection   Easy Responsive Tabs
*
* @description  Describes style declarations for Easy Responsive Tabs extension
*
* @author       Samson Onna
* @link         samson3d@gmail.com
* @version      1.0.0
*/

$tabs-btn-height: 40px;
$tabs-btn-indent: 0;

$tabs-btn-color: $gray;
$tabs-btn-background: $white;

$tabs-btn-active-color: $gray-darker;
$tabs-btn-active-background: $brand-school-bus-yellow;

$tabs-btn-indent: 2px;
$tabs-btn-border: 1px solid #ebebeb;

$tabs-btn-padding: 14px 54px 12px;

$tab-padding: 20px 0;

.resp-tabs-list {
  li {
    cursor: pointer;

    &:hover {

    }
  }
}

.resp-accordion {
  display: none;
}

.resp-tab-content {
  display: none;
}

.resp-tab-content-active {
  display: block;
}

.responsive-tabs-default {
  .resp-tabs-list {
    display: none;
    margin-bottom: 5px;
    > li + li {
      margin-left: $tabs-btn-indent;
    }
  }

  .resp-accordion, .resp-tabs-list > li {
    display: block;
    padding: $tabs-btn-padding;
    background: $tabs-btn-background;
    border: $tabs-btn-border;
    //text-transform: uppercase;
    text-align: center;
    font-weight: 500;
    font-size: 14px;
    cursor: pointer;
    letter-spacing: .12em;
    @include transition(.3s ease-out all);

    &:hover, &.resp-tab-active {
      color: $tabs-btn-active-color;
      background: $tabs-btn-active-background;
    }
  }

  .resp-accordion {
    display: block;
    border-radius: 0;
  }

  .resp-tab-content {
    padding: $tab-padding;
    display: none;
  }
  &-vertical {
    @media (min-width: $screen-sm-min) {
      display: -ms-flexbox !important;
      display: -webkit-flex !important;
      display: flex !important;
      .resp-tabs-list {
        @include flex-direction(column);
        min-width: 185px;
        > li {
          display: block;
          & + li {
            margin-left: 0;
          }
        }
      }
      .resp-tab-content {
        padding: 0 0 0 60px;
      }
    }
  }
  &-classic {
    .resp-tabs-list {
      @include justify-content(space-between);
      border-bottom: 1px solid $gray-lighter;
      > li {
        padding: 0 0 10px;
        border: 0;
        color: $gray-darker;
        border-bottom: 2px solid transparent;
        &:hover, &.resp-tab-active {
          background-color: transparent;
          border-color: $brand-school-bus-yellow;
        }
      }
    }
    .resp-accordion {
      color: $gray-darker;
      border: 0;
      border-bottom: 1px solid transparent;
      background-color: transparent;
      &:hover, &.resp-tab-active {
        background-color: transparent;
        border-color: $brand-school-bus-yellow;
      }
    }
    .resp-tab-content {
      padding: 30px 0;
    }

    &-vertical {
      @media (min-width: $screen-sm-min) {
        display: -ms-flexbox !important;
        display: -webkit-flex !important;
        display: flex !important;
        .resp-tabs-list {
          @include flex-direction(column);
          @include align-items(stretch);
          @include justify-content(space-between);
          min-width: 204px;
          border: 0;
          border-right: 1px solid $gray-lighter;
          > li {
            display: block;
            text-align: left;
            border: 0;
            padding: 0;
            border-right: 3px solid transparent;
            & + li {
              margin-top: 24px;
              margin-left: 0;
            }
            &:hover, &.resp-tab-active {
              background-color: transparent;
              border-color: $brand-school-bus-yellow;
            }
          }
        }
        .resp-tab-content {
          padding: 0 0 0 50px;
        }
      }
    }

  }
}

.responsive-tabs:not(.resp-easy-accordion) {
  @media (min-width: 768px) {
    .resp-accordion {
      display: none;
    }

    .resp-tabs-list {
      @include display-flex;
    }
  }
}


.responsive-tabs-accordion {
  text-align: left;
  .resp-accordion {
    display: block;
  }
  .resp-accordion{
    position: relative;
    padding: 31px 50px 31px 15px;
    @media (min-width: $screen-xs-min) {
      padding: 31px 65px 31px 50px;
    }
    font-size: 18px;
    color: $gray;
    cursor: pointer;
    border-bottom: 1px solid $gray-lighter;
    @include transition(.3s ease-out all);

    &:hover, &.resp-tab-active {
      border-color: $gray-darker;
      color: $white;
      background-color: $gray-darker;
      .resp-arrow {
        &:before {
          content: '\e5ce';
          color: $white;
        }
      }
    }
    &.resp-tab-active {
      //padding: 42px 50px 21px;
    }
  }
  .resp-arrow {
    position: absolute;
    right: 35px;
    top: 50%;
    @include transform(translateY(-50%));
    &:before {
      content: '\e5cf';
      font-family: 'Material Icons', sans-serif;
      font-size: 20px;
      color: $gray;
    }

  }
  .resp-tab-content {
    padding: 0 15px 50px;
    @media (min-width: $screen-xs-min) {
      padding: 0 50px 50px;
    }
    color: $gray;
    background-color: $gray-darker;

  }


  &-alternative {
    .resp-accordion {
      color: $gray;
      background-color: transparent;
      padding: 31px 15px 31px 50px;
      @media (min-width: $screen-xs-min) {
        padding: 31px 50px;
      }
      &:hover, &.resp-tab-active {
        color: $gray-darker;
        background-color: transparent;
        border-color: $gray-lighter;
      }
      .resp-arrow {
        &:after {
          content: '';
          position: absolute;
          left: 9px;
          top: -9px;
          width: 2px;
          height: 20px;
          background-color: $brand-primary;
        }
      }
      &.resp-tab-active {
        border-color: transparent;
        .resp-arrow {
          &:after {
            content: '';
            visibility: hidden;
            @include opacity(0);
          }
        }
      }
    }
    .resp-arrow {
      left: 0;
      right: auto;
      width: 20px;
      height: 2px;
      background-color: $brand-primary;
      &:before {
        content: '';
      }
    }
    .resp-tab-content {
      color: $gray;
      padding: 0 15px 40px;
      @media (min-width: $screen-xs-min) {
        padding: 0 50px 40px;
      }
      background-color: transparent;
      border-bottom: 1px solid $gray-lighter;
    }
  }
}

