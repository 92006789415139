//
// Custom form styles
// --------------------------------------------------

.rd-mailform {
  position: relative;
}

// Normalize non-controls
// --------------------------------------------------

label {
  margin-bottom: 10px;
  font-weight: 400;
}

// Common form controls
// --------------------------------------------------

.input-sm,
.input-lg,
.form-control {
  font-size: $input-font-size;
  &, &:focus {
    box-shadow: none;
  }
}

textarea.form-control {
  padding-top: 21px;
  height: $textarea-default-height;
  min-height: $textarea-default-min-height;
  max-height: $textarea-default-max-height;
  resize: vertical;
}

.form-control {
  -webkit-appearance: none;
  line-height: 22px;
  border: 0;
  border-bottom: 2px solid $input-border;
  &:focus {
    outline: 0;
    border-color: $input-border;
  }
}


// Form groups
// -------------------------

.form-group {
  position: relative;
}

// Form labels
// -------------------------

.form-label {
  position: absolute;
  top: 31px;
  left: $padding-base-horizontal;
  font-size: $input-font-size;
  color: $input-color-placeholder;
  pointer-events: none;
  z-index: 9;
  transition: .3s;
  @include transform(translateY(-50%));

  &.focus {
    opacity: 0;
  }

  &.auto-fill {
    color: $input-color;
  }
}

.form-label-outside {
  @media (min-width: $screen-sm-min) {
    position: static;
    &, &.focus, &.auto-fill {
      @include transform(none);
      color: $input-color-placeholder;
      font-size: $input-font-size;
    }
  }
}

// Form validation
// -------------------------
.form-validation {
  position: absolute;
  right: 1px;
  top: calc(100% + 2px);
  font-size: 11px;
  line-height: 11px;
  color: $brand-danger;
  margin-top: 2px;
  transition: .3s;
  z-index: 11;
}

// Error Styling
//
.has-error {
  @include form-control-validation-custom($state-danger-text, $state-danger-bg, $state-danger-bg);
}

// Success Styling
//
.has-success {
  @include form-control-validation-custom($state-success-text, $state-success-bg, $state-success-bg);
}

// Warning Styling
//
.has-warning {
  @include form-control-validation-custom($state-warning-text, $state-warning-bg, $state-warning-bg);
}

// Info Styling
//
.has-info {
  @include form-control-validation-custom($state-info-text, $state-info-bg, $state-info-bg);
}


// Form output
// -------------------------

#form-output-global {
  position: fixed;
  bottom: 30px;
  left: 15px;
  visibility: hidden;
  @include transform(translateX(-500px));
  transition: .3s all ease;
  z-index: 9999999;

  &.active {
    @include transform(translateX(0));
    visibility: visible;
  }

  @media (min-width: $screen-xs) {
    left: 30px;
  }
}

.form-output {
  position: absolute;
  top: 100%;
  left: 0;
  font-size: 14px;
  line-height: 1.5;
  margin-top: 2px;
  transition: .3s;
  opacity: 0;
  visibility: hidden;

  &.active {
    opacity: 1;
    visibility: visible;
  }
  &.error {
    color: $brand-danger;
  }
  &.success {
    color: $brand-success;
  }
}

// Radio and Checkbox Custom
// -------------------------

// Base Styles
//
.radio .radio-custom,
.radio-inline .radio-custom,
.checkbox .checkbox-custom,
.checkbox-inline .checkbox-custom {
  opacity: 0;

  &, &-dummy {
    position: absolute;
    width: 14px;
    height: 14px;
    margin-left: -20px;
    margin-top: 5px;
    outline: none;
    cursor: pointer;
  }

  &-dummy {
    pointer-events: none;
    background: $gray-lighter;
    box-shadow: inset $shadow-area-xxs;

    &:after {
      position: absolute;
      opacity: 0;
    }
  }

  &:focus {
    outline: none;
  }
}

.radio-custom:checked + .radio-custom-dummy:after,
.checkbox-custom:checked + .checkbox-custom-dummy:after {
  opacity: 1;
}

// Custom Radio Styles
//
.radio,
.radio-inline {
  .radio-custom-dummy {
    border-radius: 50%;

    &:after {
      content: '';
      top: 3px;
      right: 3px;
      bottom: 3px;
      left: 3px;
      background: $gray-darker;
      border-radius: 50%;
    }
  }
}

// Custom Checkbox Styles
//
.checkbox,
.checkbox-inline {
  padding-left: 20px;
  .checkbox-custom-dummy {
    pointer-events: none;
    border-radius: $border-radius-small;
    margin-left: 0;
    left: 0;

    &:after {
      content: '\e5ca';
      font-family: 'Material Icons';
      font-size: 20px;
      line-height: 10px;
      position: absolute;
      top: 0;
      left: 0;
      color: $gray-darker;
    }
  }
}


.form-control {

  &-subscribe {
    padding-left: 28px;
    padding-right: 28px;
    border: 0;
    background-color: $white;
  }
}

.form-label {
  &-subscribe {
    left: 28px;
  }
}

.rd-mailform-subscribe {
  @include display-flex;
  @include align-items(center);
  @include flex-direction(column);
  .form-group {
    margin-bottom: 0;
    min-width: 95%;
  }
  .btn {
    min-width: 60%;
    margin-top: 25px;
    margin-left: 10px;
    padding-left: 5px;
    padding-right: 5px;
  }
  @media (min-width: $screen-xs-min) {
    @include flex-direction(row);
    .form-group {
      min-width: 65.57%;
    }
    .btn {
      min-width: 32.78%;
      margin-top: 0;
    }
  }
}

.rd-mailform-subscribe-default {
  position: relative;
  .form-group {
    .form-validation {
      right: 76px;
    }
  }
  .form-control-subscribe {
    padding-right: 78px;
  }
  button.btn {
    position: absolute;
    padding: 15px 22px;
    top: 0;
    bottom: 0;
    right: 0;
    border: 0;
    &:before {
      content: '\f1d8';
      font-family: 'FontAwesome', sans-serif;
      font-size: 14px;
      letter-spacing: .120em;
      color: $gray-darker;
      @include transition(.3s ease-out all);
    }
    &:hover {
      color: $white;
      background-color: $brand-primary;
      border-color: transparent;
      &:before {
        color: $white;
      }
    }
  }
}

button {
  @include transition(.3s ease-out all);
}

textarea.form-control-sm {
  height: 100px;
  min-height: 100px;
  max-height: 100px;
}