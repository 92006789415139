//Products
.product {
  display: inline-block;
  position: relative;
  overflow: hidden;
  margin: 0 auto;
  text-align: left;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    @include gradient-vertical(rgba(#ffffff, .0), #316ccd, 0, 100%);
    opacity: .5;
    @include transition(.3s ease-out all);
  }

  &-content {
    display: block;
    @include transition(.3s ease-out all);
    &:hover {
      opacity: .85;
    }
  }

  .small {
    display: block;
    position: absolute;
    left: 0;
    bottom: 15px;
    max-width: 240px;
    padding: 11px 20px 9px;
    line-height: math.div(20, 12);
    font-weight: 700;
    letter-spacing: .340em;
    //text-transform: uppercase;
    color: $white;
    background-color: $brand-primary;
    @include transform(translateX(-40px));
    visibility: hidden;
    @include opacity(0);
    @include transition(.3s ease-out all);
    @media (min-width: $screen-xs-min) {
      bottom: 50px;
    }
  }
  &:hover {
    &:before {
      content: '';
      opacity: 0;
    }
    .product-content {
      &:hover {
        opacity: .85;
      }
    }
    .small {
      visibility: visible;
      @include opacity(1);
      @include transform(translateX(0));
      @include transition(.5s ease-out all);
      &:hover {
        opacity: .85;
      }
    }
  }
}

.tablet, .ios, .ipad, .iphone, .mobile {
  .product {
    &:before {
      content: '';
      opacity: 0;
    }
    .product-content {
      &:hover {
        opacity: .85;
      }
    }
    .small {
      visibility: visible;
      @include opacity(1);
      @include transform(translateX(0));
      @include transition(.5s ease-out all);
      &:hover {
        opacity: .85;
      }
    }
  }
}

