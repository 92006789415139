/**
 *	This element is created inside your target element
 *	It is used so that your own element will not need to be altered
 **/
.time_circles {
    position: relative;
    width: 100%;
    height: 100%;
}

/**
 *	This is all the elements used to house all text used
 * in time circles
 **/
.time_circles > div {
    position: absolute;
    text-align: center;
    font-family: $font-family-sec;
    top: 50%!important;
    transform: translateY(-63%)!important;
    -webkit-transform: translateY(-63%)!important;
}

@media (min-width: $screen-lg) {
    #DateCountdown{
        width: 100%;
    }
    }

/**
 *	Titles (Days, Hours, etc)
 **/
.time_circles > div > h4 {
    margin: 0;
    padding: 0;
    text-align: center;
    //text-transform: uppercase;
    font-size: 14px!important;
    color: $gray-dark;
    position: absolute;
    bottom: -65px;
    left: 0;
    right: 0;

    & + *{
        margin-top: 0;
    }
}

/**
 *	Time numbers, ie: 12
 **/
.time_circles > div > span {
    display: block;
    font-size: 20px;
    text-align: center;
    font-weight: bold;

    @media (min-width: $screen-sm) {
       font-size: 30px;
    }

    @media (min-width: $screen-lg) {
        font-size: 36px;
    }
}

