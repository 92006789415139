//
// Sections
// --------------------------------------------------

// Section Spacing
// -------------------------

$insets: (0, 25px,  50px, 55px, 75px, 80px, 100px, 120px, 130px, 140px, 150px, 200px, 230px, 260px);

@include indent-responsive(section, '', (padding-top, padding-bottom), $medias, $insets);
@include indent-responsive(section, top, padding-top, $medias, $insets);
@include indent-responsive(section, bottom, padding-bottom, $medias, $insets);

.section-100-vh {
  padding-top: 75px;
  padding-bottom: 75px;
  @media (min-width: $screen-md-min) {
    padding-top: 100px;
    padding-bottom: 100px;
    min-height: 100vh;
  }
  &.section-video {

    @media (min-width: $screen-sm-min) and (max-width: $screen-md-min - 1) {
      padding-top: 120px;
      padding-bottom: 120px;
    }
    @media (max-height: 600px) {
      padding-top: 75px;
      padding-bottom: 75px;
    }
  }
}


.ie-11 {
  .section-100-vh {
    @media (min-width: $screen-md-min) {
      height: 100vh;
    }
  }
}