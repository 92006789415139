//
// Offsets
// --------------------------------------------------

// Insets
// -------------------------

$insets: (0, 10px, 15px, 20px, 25px, 30px, 35px, 40px, 45px, 50px, 55px, 60px, 65px, 70px, 90px, 120px, 150px);

@include indent-responsive(inset, left, padding-left, $medias, $insets);
@include indent-responsive(inset, right, padding-right, $medias, $insets);

// Responsive
// -------------------------
$preffix: (-70px, -30px, -25px, -10px, -5px, 0, 5px, 10px, 15px, 30px, 40px, 45px, 70px);
@include indent-responsive(preffix, left, margin-left, $medias, $preffix);
$postfix: (-70px, -30px, -25px, -10px, -5px, 0, 5px, 10px, 15px, 30px, 40px, 45px, 70px);
@include indent-responsive(postfix, right, margin-right, $medias, $postfix);
$offsets: (0, 5px, 10px, 15px, 20px, 25px, 30px, 35px, 40px, 45px, 50px, 55px, 60px, 65px, 70px, 90px, 125px, 150px);
@include indent-responsive(offset, top, margin-top, $medias, $offsets);
html .page {
  @include indent-responsive(offset, top, margin-top, $medias, $offsets);
}

// Elements
// -------------------------
#{headings()} {
  & + p {
    margin-top: 35px;
  }

  p + & {
    margin-top: 60px;
    @media (min-width: $screen-md-min) {
      margin-top: 75px;
    }
  }
}

h3 + p {
  margin-top: 20px;
}

h4 + p {
  margin-top: 15px;
}

img + h5, a + h5 {
  margin-top: 35px;
}

p + p {
  margin-top: 10px;
  @media (min-width: $screen-md-min) {
    margin-top: 25px;
  }
}

p + h3 {
  margin-top: 55px;
}

p + h4 {
  margin-top: 50px;
}

p + h5 {
  margin-top: 45px;
}

p + h6 {
  margin-top: 50px;
}

h2 + h6, h2 + p, h6 + p {
  margin-top: 20px;
  @media (min-width: $screen-md-min) {
    margin-top: 30px;
  }
}

h5 + p {
  margin-top: 15px;
}

p + time {
  margin-top: 25px;
}

h6 + .small {
  display: block;
  margin-top: 5px;
}

img + img {
  margin-top: 30px;
}

.shell + .range {
  margin-top: 45px;
}

.h6-with-small + p {
  margin-top: 10px;
}

* + .list-inline-lg {
  margin-top: 25px;
}

.range + .range {
  margin-top: 50px;
  @media (min-width: $screen-md-min) {
    margin-top: 70px;
  }
}

* + .owl-carousel-wrap {
  margin-top: 40px;
}

p + .table-responsive {
  margin-top: 35px;
}

h2 + .table-responsive {
  margin-top: 35px;
}

h1 + h6 + p {
  margin-top: 30px;
  @media (min-width: $screen-xl-min) {
    margin-top: 50px;
  }
}

img.img-responsive + h6 + p {
  margin-top: 5px;
}

p + h5 + p {
  margin-top: 30px;
  & + .unit {
    margin-top: 30px;
  }
}

.table-responsive + h2 {
  margin-top: 60px;
  @media (min-width: $screen-md-min) {
    margin-top: 90px;
  }
}

* + .link-custom {
  margin-top: 15px;
  @media (min-width: $screen-md-min) {
    margin-top: 35px;
  }
}

h1 + h6 {
  margin-top: 25px;
}

img + h6 {
  margin-top: 25px;
  & + .small {
    display: block;
    margin-top: 10px;
    & + p {
      margin-top: 10px;
    }
  }
}

h4 + .list-marked {
  margin-top: 30px;
}

h1 + h2 {
  margin-top: 30px;
}

p + p + .unit {
  margin-top: 30px;
}

h2 + .rd-mailform {
  margin-top: 20px;
}

.range + .btn {
  margin-top: 35px;
  @media (min-width: $screen-md-min) {
    margin-top: 55px;
  }
}

h2 + .rd-mailform-subscribe {
  @media (min-width: $screen-md-min) {
    margin-top: 50px;
  }
}

.range-offset {
  .range + .range + .range {
    @media (min-width: $screen-md-min) {
      margin-top: 60px;
    }
    @media (min-width: $screen-lg-min) {
      margin-top: 80px;
    }
    @media (min-width: $screen-xl-min) {
      margin-top: 120px;
    }
  }
}

.range-offset-1 {
  .range + .range {
    margin-top: 45px;
  }
  .range + .range + .range {
    margin-top: 60px;
    @media (min-width: $screen-md-min) {
      margin-top: 80px;
    }
    @media (min-width: $screen-lg-min) {
      margin-top: 100px;
    }
  }
}

h1 + .inset-lg-right-45 {
  margin-top: 50px;
  @media (min-width: $screen-lg-min) {
    margin-top: 66px;
  }
}

.list-xl + p {
  margin-top: 40px;
  @media (min-width: $screen-lg-min) {
    margin-top: 60px;
  }
  @media (min-width: $screen-xl-min) {
    margin-top: 90px;
  }
}

.range-offset-2 {
  h1 {
    @media (min-width: $screen-xl-min) {
      margin-top: 10px;
    }
  }
  h6, p {
    @media (min-width: $screen-lg-min) and (max-width: $screen-xl-min - 1) {
      padding-right: 30px;
    }
  }
  h1 + h6 {
    margin-top: 32px;
  }
  h2 + h6 {
    margin-top: 15px;
    @media (min-width: $screen-lg-min) {
      margin-top: 25px;
    }
  }
  h6 + p {
    margin-top: 15px;
    @media (min-width: $screen-lg-min) {
      margin-top: 25px;
    }
  }
  .range + .range {
    margin-top: 50px;
    @media (min-width: $screen-lg-min) {
      margin-top: 60px;
    }
    @media (min-width: $screen-xl-min) {
      margin-top: 20px;
    }
  }
}

* + .pagination-custom {
  margin-top: 60px;
  @media (min-width: $screen-md-min) {
    margin-top: 80px;
  }
  @media (min-width: $screen-lg-min) {
    margin-top: 100px;
  }
}

* + .list-index {
  margin-top: 40px;
}

.range + .range + .bg-table.bg-table-well {
  margin-bottom: 0;
}

h6 + .list-xs {
  margin-top: 20px;
}

.list-xs + h6,
h6 + h6 {
  margin-top: 45px;
}

* + .countdown {
  margin-top: 40px;
}

.countdown + h2 {
  margin-top: 40px;
  @media (min-width: $screen-md-min) {
    margin-top: 60px;
  }
}

.progress-bar-circle + * {
  margin-top: 15px;
  @media (min-width: $screen-md-min) {
    margin-top: 25px;
  }
}

h5 + img {
  margin-top: 40px;
}

img + h6 {
  > span.small {
    margin-top: 10px;
  }
}

h2 + .btn {
  margin-top: 30px;
  @media (min-width: $screen-md-min) {
    margin-top: 45px;
  }
}

* + .rd-mailform-subscribe-default {
  margin-top: 40px;
  @media (min-width: $screen-md-min) {
    margin-top: 55px;
  }
}

p + .group {
  margin-top: 30px;
  @media (min-width: $screen-md-min) {
    margin-top: 40px;
  }
}