// Custom Mixins
// --------------------------------------------------

// Utilities
@import "mixins/functions";
@import "mixins/flex";
@import "mixins/vendors-custom";
@import "mixins/text-utilities";
@import "mixins/pull-utilities";
@import "mixins/visibility-utilities";
@import "mixins/backgrounds";
@import "mixins/indent-utilities";
@import "mixins/unit-utilities";
@import "mixins/element-groups";
@import "mixins/buttons";
@import "mixins/forms-custom";
@import "mixins/link";

// Grid Framework
@import "mixins/flex-grid-framework";

@mixin responsive-offset($offset) {
  position: relative;
  @include transform(translateY(-$offset));
  margin-bottom: -$offset;

  &:before {
    content: '';
    display: table;
  }

  > * {
    margin-top: $offset;

    &:before {
      content: '';
      display: table;
    }
  }
}

@mixin responsive-offset-media($offset) {
  @include transform(translateY(-$offset));
  margin-bottom: -$offset;

  > * {
    margin-top: $offset;
  }
}