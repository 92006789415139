/*
* @subsection   RD Google Map
*
* @description  Describes style declarations for RD Google Map extension
*
* @author       Evgeniy Gusarov
* @link         https://ua.linkedin.com/pub/evgeniy-gusarov/8a/a40/54a
* @version      1.0.0
*/
$map-height: 200px;
$map-xs-height: 250px;
$map-sm-height: 550px;
$map-md-height: 665px;

.rd-map-wrap {
  @media (min-width: $screen-sm-min) {
    position: relative;
    height: $map-md-height;
    .rd-google-map {
      position: absolute;
      right: 0;
      left: calc(50% + 20px);
      top: 0;
      bottom: 0;
      z-index: 100;
      height: auto;
    }
  }

}

.rd-google-map__model {
  color: #333;
  height: $map-xs-height;

  img {
    max-width: none !important;
  }

  @media (min-width: $screen-xs-min) {
    height: $map-xs-height;
  }

  @media (min-width: $screen-sm-min) {
    height: $map-sm-height;
  }
}

.map_locations {
  display: none;
}
